import AddContact from './AddContact'
import './App.css'
import MailSend from './MailSend'

function App () {
  return (
    <div className='App'>
      <MailSend />
      <AddContact />
    </div>
  )
}

export default App
