import React, { useState } from 'react'

const AddContact = () => {
  const [url, setUrl] = useState(
    'https://hook.eu1.make.com/jqyqtq8yo5rxs9v297p9tguxwvpywpvv'
  )

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [data, setData] = useState({})

  const handleSubmit = async e => {
    e.preventDefault()
    setEmail(e.target[0].value)
    setName(e.target[1].value)

    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: name, email: email })
    })
    const content = await rawResponse.json()
    setData(content)

    console.log(data)

    console.log('Email: ', email, 'Name: ', name)
  }

  return (
    <div className='main'>
      <form className='formik' onSubmit={handleSubmit}>
        <input type='email' placeholder='Email' />
        <input type='name' placeholder='name' />
        <button>Dodaj Kontakt</button>
      </form>
    </div>
  )
}

export default AddContact
