import React, { useState, useRef } from 'react'
import './MailSend.css'
import { Editor } from '@tinymce/tinymce-react'

const MailSend = () => {
  const editorRef = useRef(null)

  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [data, setData] = useState({})
  const [url, setUrl] = useState(
    'https://hook.eu1.make.com/ge1fgg9q9wep79jtzeegop7hppgohq6r'
  )

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent())
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setEmail(e.target[0].value)
    setSubject(e.target[1].value)
    setMessage(editorRef.current.getContent())

    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email, subject: subject, message: message })
    })
    const content = await rawResponse.json()
    setData(content)

    console.log(data)

    console.log('Email: ', email, 'Subject: ', subject, 'Message: ', message)
  }

  return (
    <div className='main'>
      <form className='formik' onSubmit={handleSubmit}>
        <input type='email' placeholder='Email' />
        <input
          style={{ marginBottom: '10px' }}
          type='subject'
          placeholder='Subject'
        />
        {/* <input className='msg' type='message' placeholder='Message' /> */}
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue='<p>This is the initial content of the editor.</p>'
          init={{
            height: 500,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'help',
              'wordcount'
            ],
            toolbar:
              'undo redo | blocks | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat ',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
          }}
        />
        <button>Wyślij</button>
      </form>

      {/* <>
        <button onClick={log}>Log editor content</button>
      </> */}
    </div>
  )
}

export default MailSend
